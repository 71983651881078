import { useQuery, gql } from '@apollo/client';

import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar } from '../snackbar/snackbarSlice';
import { setProjects, setProject } from './projectSlice';
import { IUser } from '../../types/user';
import { IProjectAccess } from '../../types/user';
import { titleCase } from '../../utils/Functions';

export const accessAllowed = (user: IUser, projectId: string, restriction: string) => {
  let project = user.projectAccess.find((projectAccess: IProjectAccess) => {
    return projectAccess.project._id === projectId;
  });

  if (project?.access.includes(restriction)) {
    return true;
  } else return false;
};

export const useProjectsQuery = () => {
  const dispatch = useAppDispatch();

  return useQuery(GETPROJECTS, {
    onCompleted: (data) => {
      dispatch(setProjects(data.projectMany));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const useProjectQuery = (name: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPROJECT, {
    variables: { filter: { name: name ? titleCase(name.replace(/-/g, ' ')) : '' } },
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      dispatch(setProject(data.projectOne));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETPROJECTS = gql`
  query projectMany {
    projectMany {
      _id
      name
    }
  }
`;

const GETPROJECT = gql`
  query projectOne($filter: FilterFindOneProjectInput) {
    projectOne(filter: $filter) {
      _id
      name
      developerName
      email
      tagLine
      salesOffice
      addresses {
        streetAddress
        city
        province
        postalCode
      }
      options {
        name
        totalAvailable
        price
        allowedUnits
        type
      }
      depositStructures {
        name
        deposits {
          name
          type
          amount
          daysDue
          dateType
          dueDate
        }
        default
      }
      mergeTemplates {
        name
        apsTemplates {
          name
          pageNumber
          attachToAps
        }
      }
      status {
        name
        code
        color
      }
      executors {
        name
        email
      }
      mortgageLimit
      commissionIncludeOptions
      emailTemplates {
        _id
        name
        subject
        html
      }
      logoGetUrl
      logoPutUrl
      imageGetUrl
      imagePutUrl
      developerLogoGetUrl
      combinedProjects {
        _id
      }
      portal {
        active
        vipActive
        showHotlist
        showFloorPlans
        sortHotlist
        showCustom
        createWorksheets
        showOptionsPrice
        showUnitTypes
        showOptions
        promo
        password
        information
        promo
        renderings {
          _id
          getUrl
          getThumbUrl
          name
        }
        photos {
          _id
          getUrl
          getThumbUrl
          name
        }
        pdfs {
          _id
          getUrl
          name
        }
        logos {
          _id
          name
          getUrl
          getThumbUrl
        }
        aerialPhotos {
          _id
          name
          getUrl
          getThumbUrl
        }
        bRoll {
          _id
          name
          getUrl
        }
        officialSite
        instaUrl
        primaryColor
        secondaryColor
        brochure
        brochureGetUrl
        brochurePutUrl
        subText
        videos {
          name
          getUrl
        }
      }
    }
  }
`;
