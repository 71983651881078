import { withRouter } from './utils/WithRouter';
import { gql } from '@apollo/client';
import { Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { PersistGate } from 'redux-persist/integration/react';

import { store } from './app/store';
import { persistor } from './app/store';
import { theme } from './theme/theme';
import ResetPassword from './components/resetPassword/ResetPassword';
import ProjectList from './components/ProjectList';
import Profile from './components/profile/Profile';
import { WorksheetProvider } from './context/WorksheetContext';
import { AppointmentProvider } from './context/AppointmentContext';
import LoginScreen from './components/login/LoginScreen';
import PublicRoute from './components/routes/PublicRoute';
import CreateWorksheet from './components/worksheet/CreateWorksheet';
import BookAppointment from './components/appointment/bookAppointment/BookAppointment';
import ThankYou from './components/ThankYou';
import RequireAuth from './components/routes/RequireAuth';
import RequireProject from './components/routes/RequireProject';
import DashboardOutlet from './components/routes/DashboardOutlet';
import ScheduleOutlet from './components/routes/ScheduleOutlet';
import Outstandings from './components/outstandings/Outstandings';
import Appointments from './components/appointment/Appointments';
import WorksheetList from './components/worksheet/WorksheetList';
import Deals from './components/deal/Deals';
import Allocations from './components/allocations/Allocations';
import Floorplans from './components/floorplans/FloorplansGrid';
import DealDetailPage from './components/deal/DealDetailPage';
import { Snackbar } from './features/snackbar/Snackbar';
import PublicAuth from './components/routes/PublicAuth';
import ProjectPortal from './components/projectInfo/ProjectPortal';

const App = (props: any) => {
  // function Redirect() {
  //   window.location.replace('https://portal.rdsre.ca/devron/101-spadina/public/book-an-appointment/660333a406eea4dfd7fd28ab');
  //   return null;
  // }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Snackbar />
          <WorksheetProvider>
            <AppointmentProvider>
              <Routes>
                <Route element={<PublicAuth />}>
                  <Route
                    element={
                      <PublicRoute>
                        <LoginScreen />
                      </PublicRoute>
                    }
                    path="/"
                  />
                  {/* <Route element={<Redirect />} path="/101-spadina" /> */}
                  <Route element={<RequireProject />}>
                    <Route element={<ProjectPortal />} path="/:projectName" />
                    <Route element={<ProjectPortal />} path="/:developerName/:projectName/vip" />
                    <Route element={<ProjectPortal />} path="/:developerName/:projectName/public" />
                    <Route element={<CreateWorksheet />} path="/:developerName/:projectName/public/:unitid/create-worksheets" />
                    <Route element={<BookAppointment />} path="/:developerName/:projectName/public/book-an-appointment" />
                    <Route element={<BookAppointment />} path="/:developerName/:projectName/public/book-an-appointment/:scheduleid" />
                  </Route>
                  <Route element={<ScheduleOutlet />}>
                    <Route element={<BookAppointment />} path="/book/:shortid" />
                  </Route>
                  <Route element={<ProjectList />} path="/developer/:developerName" />
                  <Route element={<ResetPassword />} path="/reset-password/:token" />
                  <Route element={<ThankYou />} {...props} path="/thank-you" />
                </Route>
                <Route element={<RequireAuth />}>
                  <Route element={<ProjectList />} path="/project-list" />
                  <Route element={<ResetPassword />} path="/reset-password" />
                  <Route element={<Profile />} path="/profile" />
                  <Route element={<DashboardOutlet />} path="/:developerName/:projectName">
                    <Route element={<CreateWorksheet />} path="vip/:unitid/create-worksheets" />
                    <Route element={<BookAppointment />} path="vip/book-an-appointment" />
                    <Route element={<Outstandings />} path="outstandings" />
                    <Route element={<ProjectPortal />} path={``} />
                    <Route element={<Profile />} path={`profile`} />
                    <Route element={<CreateWorksheet />} path="create-worksheets" />
                    <Route element={<CreateWorksheet />} path=":unitid/create-worksheets" />
                    <Route element={<CreateWorksheet />} path="worksheets/:worksheetid" />
                    <Route element={<Appointments />} path="appointments" />
                    <Route element={<BookAppointment />} path="book-an-appointment" />
                    <Route element={<WorksheetList />} path="worksheets" />
                    <Route element={<Deals />} path="deals" />
                    <Route element={<Allocations />} path="allocations" />
                    <Route element={<Floorplans />} path="floorplans" />
                    <Route element={<DealDetailPage />} path="deals/:dealid" />
                  </Route>
                </Route>
              </Routes>
            </AppointmentProvider>
          </WorksheetProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

interface Definition {
  kind: string;
  operation?: string;
}

const LOGERROR = gql`
  mutation errorLogCreateOne($record: CreateOneErrorLogInput!) {
    errorLogCreateOne(record: $record) {
      recordId
    }
  }
`;

export default withRouter(App);
