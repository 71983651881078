import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import { IProject } from '../../types/project';
import { IPortal } from '../../types/portal';

const getCurrentDateString = () => new Date(Date.now()).toISOString();

const initialState: IProjectState = {
  projects: [],
  project: {
    _id: '',
    name: '',
    image: '',
    developerName: '',
    addresses: [],
    salesOffice: '',
    imageGetUrl: null,
    imagePutUrl: '',
    logoPutUrl: '',
    logoGetUrl: null,
    developerLogoGetUrl: null,
    email: '',
    options: [],
    depositStructures: [
      {
        name: '',
        deposits: [
          {
            name: '', // 1st Deposit, 2nd Deposit etc
            type: '', // Fixed or Percent
            amount: 0, // 5,000 or 2.5%
            daysDue: 0, // 30 days
            dateType: '',
            dueDate: getCurrentDateString(), // Specific Date
          },
        ],
        default: true,
      },
    ],
    mergeTemplates: [
      {
        name: 'floorplan',
        apsTemplates: [
          {
            name: 'Main APS',
            pageNumber: 0,
            attachToAps: '',
          },
        ],
      },
    ],
    executors: [],
    status: [],
    tagLine: '',
    // emailTemplates: [],
    portal: {
      active: false,
      vipActive: false,
      showHotlist: false,
      showFloorPlans: false,
      sortHotlist: 'low',
      showOptionsPrice: false,
      showUnitTypes: false,
      showOptions: true,
      showCustom: true,
      createWorksheets: false,
      password: '',
      primaryColor: '',
      secondaryColor: '',
      information: [],
      promo: '',
      renderings: [],
      photos: [],
      pdfs: [],
      logos: [],
      aerialPhotos: [],
      bRoll: [],
      officialSite: '',
      instaUrl: '',
      subText: '',
      videos: [],
      brochureGetUrl: '',
    },
    combinedProjects: [],
  },
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setProject: (state, action) => {
      state.project = action.payload;
    },
  },
});

export const { setProjects, setProject } = projectSlice.actions;

export const selectProjects = (state: RootState) => state.project.projects;
export const selectProject = (state: RootState) => state.project;

interface IProjectState {
  projects: IProject[];
  project: IProject;
}

export default projectSlice.reducer;
