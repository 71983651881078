import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Grid, Button, Box } from '@mui/material';
import { IActiveProjects, IProject } from '../types/project';
import ProjectCard from './ProjectCard';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import LoadingWrapper from './common/LoadingWrapper';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/auth/authSlice';
import { setProject } from '../features/project/projectSlice';
import { useAppDispatch } from '../app/hooks';

const ProjectList = () => {
  const storeDispatch = useAppDispatch();
  const [projects, setProjects] = useState<IProject[]>([]);
  const user = useSelector(selectUser);
  const params = useParams();

  storeDispatch(
    setProject({
      _id: '',
      name: '',
      image: '',
      developerName: '',
      addresses: [],
      imageGetUrl: null,
      imagePutUrl: '',
      logoPutUrl: '',
      logoGetUrl: null,
      email: '',
      options: [],
      depositStructures: [
        {
          name: '',
          deposits: [
            {
              name: '', // 1st Deposit, 2nd Deposit etc
              type: '', // Fixed or Percent
              amount: 0, // 5,000 or 2.5%
              daysDue: 0, // 30 days
              dateType: '',
              dueDate: new Date(Date.now()).toISOString(), // Specific Date
            },
          ],
          default: true,
        },
      ],
      mergeTemplates: [
        {
          name: 'floorplan',
          apsTemplates: [
            {
              name: 'Main APS',
              pageNumber: 0,
              attachToAps: '',
            },
          ],
        },
      ],
      executors: [],
      status: [],
      // emailTemplates: [],
      portal: {
        active: false,
        vipActive: false,
        showHotlist: false,
        showFloorPlans: false,
        showOptionsPrice: false,
        showUnitTypes: false,
        showOptions: true,
        showCustom: true,
        sortHotlist: 'low',
        createWorksheets: false,
        password: '',
        primaryColor: '',
        secondaryColor: '',
        information: [],
        renderings: [],
        photos: [],
        pdfs: [],
        logos: [],
        aerialPhotos: [],
        bRoll: [],
        officialSite: '',
        instaUrl: '',
        subText: '',
        videos: [],
        brochureGetUrl: '',
      },
      combinedProjects: [],
    })
  );

  const { loading } = useQuery<IActiveProjects>(ACTIVEPROJECTS, {
    variables: { developer: Object.keys(params).length !== 0 ? params.developerName : 'project-list' },
    onCompleted: (data) => {
      setProjects(data.getAllActiveProjects);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return loading ? (
    <LoadingWrapper title={'Projects are loading...'} modal={false} />
  ) : (
    <Background>
      <div style={{ textAlign: 'center' }}>
        <Box sx={{ margin: '30px 0' }}>
          <img src="https://s3.ca-central-1.amazonaws.com/app.rdsre.ca/logo.png" height="140px" alt="RDS Real Estate" />
        </Box>
        {!user ? (
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary">
              Realtor Login
            </Button>
          </Link>
        ) : null}
      </div>
      {projects.length > 0 ? (
        <GridContainer container spacing={2}>
          {projects.map((project: IProject, index: number) => {
            return <ProjectCard params={params} project={project} user={user} key={index} />;
          })}
        </GridContainer>
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <em>Please login to view projects</em>
        </Box>
      )}
    </Background>
  );
};

const GridContainer = styled(Grid)({
  padding: '20px',
  margin: 0,
  width: '100%',
});

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ACTIVEPROJECTS = gql`
  query getAllActiveProjects($developer: String) {
    getAllActiveProjects(developer: $developer) {
      _id
      name
      developerName
      imageGetUrl
      portal {
        active
        vipActive
        subText
      }
    }
  }
`;

export default ProjectList;
