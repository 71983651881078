import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { IProject } from '../../../types/project';
import { IUser } from '../../../types/user';
import { ISchedule } from '../../../types/appointment';
import { urlName } from '../../../utils/Functions';

// Project Imports

const ConfirmSubmission = (props: ChildProps) => {
  const navigate = useNavigate();
  /* Props */
  const { project, schedules } = props;

  return (
    <FirstPageContainer style={{ width: '100%' }} image={project.imageGetUrl!}>
      <ColorOverlay style={{ width: '100%' }}></ColorOverlay>
      <TextOverlay>
        {schedules.length
          ? schedules[0].endText
          : 'Thank you for booking an appointment! An email will be sent to you confirming your appointment details.'}
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" sx={{ color: '#fff' }} onClick={() => navigate(`/${urlName(project.name)}`)}>
            View {project.name} Portal
          </Button>
        </Box>
      </TextOverlay>
    </FirstPageContainer>
  );
};

interface ChildProps {
  project: IProject;
  schedules: ISchedule[];
  user: IUser;
}

const FirstPageContainer = styled.div<{ image: string }>`
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${(props) => props.image});
  color: #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ColorOverlay = styled.div`
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: absolute;
`;

const TextOverlay = styled.div`
  color: #fff;
  filter: brightness(150%);
  font-size: calc(2vw + 3px);
  font-weight: 500;
  width: 70%;
  margin: auto;
  text-align: center;
`;

export default ConfirmSubmission;
