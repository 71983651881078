import JSZip from 'jszip';
import saveAs from 'file-saver';
import { degrees, PDFDocument, rgb, grayscale, PageSizes } from 'pdf-lib';
import ExcelJS from 'exceljs';
import React from 'react';
import { IProject } from '../types/project';
import { IUnit } from '../types/unit';
import { subHours, addHours, format } from 'date-fns';

export const numToCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const formatPhoneNumber = (value: string) => {
  let num = value.replace(/[^\d]/g, '');
  return `${
    num.length > 3 && num.length < 11 ? `${num.slice(0, 3)}-${num.length > 6 ? `${num.slice(3, 6)}-${num.slice(6)}` : num.slice(3)}` : num
  }`;
};

export const formatSin = (value: string) => {
  let num = value.replace(/[^\d]/g, '');
  return `${
    num.length > 3 && num.length < 10 ? `${num.slice(0, 3)} ${num.length > 6 ? `${num.slice(3, 6)} ${num.slice(6)}` : num.slice(3)}` : num
  }`;
};

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const validatePassword = (password: string) => {
  // eslint-disable-next-line
  let re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/i;
  return re.test(password);
};

export const dateFormatter = (date: Date) => {
  if (date === null) return '';
  const d = new Date(date);
  return d.toLocaleDateString();
};

export const convertAllDates = (timestamp: any, dateFormat: string) => {
  return format(new Date(timestamp), dateFormat);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeFirstLetterEachWord = (string: string) => {
  const words = string.split(' ');
  let ret = '';
  for (let word of words) {
    ret += word.charAt(0).toUpperCase();
    ret += word.slice(1);
    ret += ' ';
  }
  if (words.length > 0) ret = ret.slice(0, ret.length - 1);
  return ret;
};

export const findDifference = (arr1: any, arr2: any) => {
  const newArr = arr1.filter((item: any) => !arr2.includes(item));
  if (newArr.length === 0) return null;
  if (newArr.filter((i: string) => i === '').length === newArr.length) return null;
  return newArr.filter((i: string) => i !== null && i !== '').filter(onlyUnique);
};

export const onlyUnique = (value: any, index: number, self: any) => self.indexOf(value) === index;

export const splitNumber = (number: number, parts: number) => {
  let n = Math.floor(number / parts);
  const arr = [];
  for (let i = 0; i < parts; i++) {
    arr.push(n);
  }
  if (arr.reduce((a, b) => a + b, 0) === number) {
    return arr;
  }
  for (let i = 0; i < parts; i++) {
    arr[i]++;
    if (arr.reduce((a, b) => a + b, 0) === number) {
      return arr;
    }
  }
};

export function download(file: any, filename: string, type: string) {
  const element = document.createElement('a');
  element.download = filename;
  let binaryData = [];
  binaryData.push(file);
  element.href = URL.createObjectURL(new Blob(binaryData, { type: type }));
  element.click();
}

export function share(file: any, image: Blob | string, filename: string, type: string) {
  let blob;

  if (file) {
    let binaryData = [];
    binaryData.push(file);
    blob = new Blob(binaryData, { type: type });
  } else {
    blob = image;
  }

  let navigator: any;
  let newFile = new File([blob], filename, { type: type });
  let filesArray = [newFile];
  navigator = window.navigator;

  let shareData = {
    title: filename,
    files: filesArray,
  };

  if (navigator.canShare && navigator.canShare(shareData)) {
    navigator
      .share({
        title: 'Floor Plans',
        files: filesArray,
      })
      .then(() => {
        return 'Share was successful.';
      })
      .catch((error: string) => {
        return `Sharing Failed. ${error}`;
      });
  } else {
    return `Your system doesn't support sharing files.`;
  }
}

export const mergePdf = async (files: any[], fileName: string, project: IProject) => {
  const mergedPdf = await PDFDocument.create();
  if (files.length > 0) {
    for (const unit of files) {
      const floorplanDoc = await PDFDocument.create();
      const floorplanImage = await fetch(unit.marketingFloorPlan).then((res) => res.arrayBuffer());
      const jpgImage = await floorplanDoc.embedJpg(floorplanImage);
      let page;
      if (jpgImage.width > jpgImage.height) {
        page = floorplanDoc.addPage([PageSizes.Letter[1], PageSizes.Letter[0]]);
        page.setSize(792, 642);
        page.drawImage(jpgImage, {
          width: 792,
          height: 612,
        });
      } else {
        page = floorplanDoc.addPage(PageSizes.Letter);
        page.setSize(612, 930);
        page.drawImage(jpgImage, {
          y: 138,
          width: 612,
          height: 792,
        });
      }
      // Draw Suite and BasePrice
      if (unit.suite) {
        page.drawText(`Suite: ${unit.suite}`, {
          x: 35,
          y: 120,
          size: 14,
          color: rgb(0, 0, 0),
        });
      }
      if (unit.basePrice) {
        page.drawText(`Price: ${numToCurrency.format(unit.basePrice)}`, {
          x: 35,
          y: 100,
          size: 14,
          color: rgb(0, 0, 0),
        });
      }
      if (unit.rental) {
        page.drawText(`RENTAL GUARANTEE AMOUNT: ${numToCurrency.format(unit.rental)}`, {
          x: 35,
          y: 80,
          size: 14,
          color: rgb(1, 0, 0),
        });
      }
      page.drawText(`Please consult a RDS Representative for details. (e) ${project.email}; E&OE. ${new Date().toLocaleDateString()}`, {
        x: 35,
        y: 20,
        size: 9,
        color: rgb(0, 0, 0),
      });
      let copiedPages = await mergedPdf.copyPages(floorplanDoc, floorplanDoc.getPageIndices());
      await copiedPages.forEach((page: any) => mergedPdf.addPage(page));
    }
    const mergedPdfFile = await mergedPdf.save();
    return mergedPdfFile;
  }
};

export const downloadImages = async (files: any[], fileName: string, project: IProject) => {
  const mergedPdf = await mergePdf(files, fileName, project);
  download(mergedPdf, fileName, 'application/pdf');
};

export const shareImages = async (files: any[], fileName: string, project: IProject) => {
  const mergedPdf = await mergePdf(files, fileName, project);
  share(mergedPdf, '', fileName, 'application/pdf');
};

export const shareDocuments = async (selectedDocuments: any[]) => {
  let documents: any = [];
  await Promise.all(
    selectedDocuments.map(async (document) => {
      let response = await fetch(document.getUrl!, { cache: 'no-cache' });
      let blob = await response.blob();
      documents.push({
        ...document,
        blob: blob,
      });
    })
  );
  let allBlobs = documents.map((blobs: any) => blobs.blob);
  let renderingsBase64 = await blobToBase64(allBlobs);
  const docs: any = await Promise.all(
    selectedDocuments.map(async (document, index) => {
      return {
        blob: renderingsBase64[index],
      };
    })
  );
  const mergedPdf = await PDFDocument.create();
  for (let i = 0; i < docs.length; ++i) {
    const pdfA = await PDFDocument.load(docs[i].blob);
    const copiedPagesA = await mergedPdf.copyPages(pdfA, pdfA.getPageIndices());
    copiedPagesA.forEach((page) => mergedPdf.addPage(page));
  }
  const mergedPdfFile = await mergedPdf.save();
  share(mergedPdfFile, '', `Documents.pdf`, 'application/pdf');
};

export const imageUrlsToBlob = async (imageUrl: string[], renderings: any[]) => {
  if (!renderings || renderings.length > 0) return [];
  let blobs = await Promise.all(
    imageUrl.map(async (url: any) => {
      const response = fetch(url).then((response) => {
        return response.blob();
      });
      return response;
      /*return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(response);
      });*/
    })
  );
  return blobs;
};

export const blobToBase64 = async (blobs: Blob[]) => {
  let base64 = await Promise.all(
    blobs.map(async (blob: Blob) => {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    })
  );
  return base64;
};

// download function with argument of array with getUrl
export const downloadMedia = async (files: any, project: string, type: string) => {
  const zip = new JSZip();
  //fetches the files and saves the files as blobs in an array

  const addFilesToZip: any = async (fileBlobs: Blob[]) => {
    if (type === 'video') {
      await fileBlobs.forEach((fileBlob: Blob, index: number) => {
        zip.file(`${project} ${index + 1}.mp4`, fileBlob);
      });
    } else {
      await fileBlobs.forEach((fileBlob: Blob, index: number) => {
        zip.file(`${project} ${index + 1}.jpg`, fileBlob);
      });
    }
  };
  //downloads the zip
  const downloadZip = (zip: JSZip) => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${project}.zip`);
    });
  };

  addFilesToZip(files);
  downloadZip(zip);
};

export const cacheImages = async (imageArray: string[]) => {
  const promises = await imageArray.map((imageUrl: string) => {
    return new Promise(function (resolve: any, reject: any) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = resolve();
      img.onerror = reject();
    });
  });

  await Promise.all(promises);
};

export const getVideoId = (videoId: string, type: string) => {
  let regExp;
  if (type === 'vimeo') {
    // eslint-disable-next-line
    regExp = videoId.match(
      /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i
    );
  } else if (type === 'youtube') {
    // eslint-disable-next-line
    regExp = videoId.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/);
  }
  if (regExp) {
    return regExp;
  } else return '';
};

export const titleCase = (string: string) => {
  var splitStr = string.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const urlName = (string: string) => {
  if (string) {
    return string.replace(/\s+/g, '-').toLowerCase();
  } else return '';
};

export const lastPathName = (string: string) => {
  return string.substring(string.lastIndexOf('/') + 1);
};

export const getDoubleDigitNumber = (num: number | string) => {
  let numeral = typeof num === 'string' ? parseInt(num) : num;
  return numeral < 10 ? '0' + numeral : numeral + '';
};

/**
 * Downloads an excel spreadsheet from data
 * @param data: an array of objects
 * @param headers: an array of the keys of the object
 * @param formattedHeaders: an array of the formatted keys of the object
 * @param backgrounds: color codes for the backgrounds of each cell
 * @param columnWidths: widths of each column
 * @param sheetName: name of the worksheet
 * @param fileName: name of the downloaded file
 */
export const downloadExcel = (
  data: any[],
  headers: string[],
  formattedHeaders: string[],
  backgrounds: any[],
  columnWidths: any,
  sheetName: string,
  fileName: string
) => {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  // Create Excel file
  const workbook = new ExcelJS.Workbook(); // Create Workbook
  const worksheet = workbook.addWorksheet(sheetName); // Create Worksheet
  worksheet.columns = headers.map((header, index) => ({ header: formattedHeaders[index], key: header, width: columnWidths[header] })); // Add columns
  worksheet.addRow(formattedHeaders);
  data.forEach((values, index) => {
    const row = worksheet.getRow(index + 2);
    row.values = values; // Set the value of each row
    headers.forEach((header) => {
      row.getCell(header).fill = {
        // Add the background color if it is defined
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: backgrounds[index][header]?.substring(1) ?? 'ffffff' },
      };
    });
    row.height = 45;
    row.commit();
  });

  // Download file
  workbook.xlsx.writeBuffer().then((file: any) => {
    const blob = new Blob([file], { type: EXCEL_TYPE });
    saveAs(blob, fileName);
  });
};

const hexToPdfRgb = (hex: string | undefined) => {
  if (!hex || hex.length !== 7) return rgb(1, 1, 1);
  return rgb(parseInt(hex.substring(1, 3), 16) / 255, parseInt(hex.substring(3, 5), 16) / 255, parseInt(hex.substring(5, 7), 16) / 255);
};

/**
 * Downloads an excel spreadsheet from data
 * @param data: an array of objects
 * @param headers: an array of the keys of the object
 * @param formattedHeaders: an array of the formatted keys of the object
 * @param backgrounds: color codes for the backgrounds of each cell
 * @param columnWidths: widths of each column
 * @param fileName: name of the downloaded file
 */
export const downloadPDF = async (
  data: any[],
  headers: string[],
  formattedHeaders: string[],
  backgrounds: any[],
  columnWidths: any,
  fileName: string,
  hotlistInfo: any = null
) => {
  if (!data.length) return;
  // Constants
  const padding = 20;
  const fontSize = 12;
  const realtorFontSize = 100;
  const textOffset = 5;
  const cellPadding = 5;
  const rowHeight = cellPadding * 2 + fontSize * 3 + textOffset;
  const headerHeight = cellPadding * 2 + fontSize + textOffset;
  const maxRowPerPage = 15;
  const logoLength = 50;
  const today = new Date();
  const time = today.toLocaleTimeString('en-us');
  let hotlistMessage =
    'All Prices, Incentives and Specifications are subject to change without notice. *Please speak with an RDS Sales Representative for details.\nE. & O.E. ';
  hotlistMessage += today.toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
  const registeredText = 'Residential Development Services ® ' + today.getFullYear();

  // Get text width
  const context = document.createElement('canvas').getContext('2d');
  context && (context.font = fontSize + 'px sans-serif');
  const timeWidth = context?.measureText(time).width ?? 0;
  const registeredWidth = context?.measureText(registeredText).width ?? 0;
  const realtorContext = document.createElement('canvas').getContext('2d');
  realtorContext && (realtorContext.font = realtorFontSize + 'px sans-serif');
  const realtorWidth = realtorContext?.measureText(hotlistInfo?.realtor ?? '').width ?? 0;

  // Get Page Dimensions
  const tableWidth = headers.reduce((prevValue, key) => prevValue + columnWidths[key] + cellPadding * 2, 0);
  const width = padding * 2 + tableWidth;
  const height = padding * 2 + rowHeight * maxRowPerPage + headerHeight + (hotlistInfo ? fontSize * 3 + logoLength : 0);

  const pdf = await PDFDocument.create();
  const numPages = Math.ceil(data.length / maxRowPerPage);

  // Embed images
  let projectLogoImage;
  if (hotlistInfo?.projectLogo) {
    let logoUrl = hotlistInfo?.projectLogo.split('?X')[0];
    const projectLogoBytes = await fetch(logoUrl).then((res) => res.arrayBuffer());
    projectLogoImage = await pdf.embedJpg(projectLogoBytes);
  }

  //const logoImage = await pdf.embedPng(logoBytes);
  for (let i = 0; i < numPages; ++i) {
    let page = pdf.addPage([width, height]); // Get page
    page.moveTo(padding, height - padding); // Add padding

    if (hotlistInfo) {
      page.moveDown(logoLength); // Make space for logos
      //page.drawImage(logoImage, { width: logoLength, height: logoLength });
      //page.moveRight(logoLength);
      projectLogoImage &&
        page.drawImage(projectLogoImage, { height: logoLength, width: projectLogoImage.width * (logoLength / projectLogoImage.height) });
      //page.moveLeft(logoLength);
    }

    // Add headers
    page.moveDown(headerHeight + 2);
    headers.forEach((header, index) => {
      page.drawRectangle({
        height: headerHeight,
        width: columnWidths[header] + cellPadding * 2,
        color: rgb(1, 1, 1),
        borderWidth: 1,
        borderColor: grayscale(0.5),
      });
      page.drawText(formattedHeaders[index], {
        x: page.getX() + cellPadding,
        y: page.getY() + headerHeight - fontSize - cellPadding,
        size: fontSize,
        lineHeight: fontSize / 2,
      });
      page.moveRight(columnWidths[header] + cellPadding * 2);
    });
    page.moveLeft(tableWidth);

    // Add rows
    for (let j = i * maxRowPerPage; j < data.length && j < i * maxRowPerPage + maxRowPerPage; ++j) {
      page.moveDown(rowHeight);
      headers.forEach((header) => {
        page.drawRectangle({
          height: rowHeight,
          width: columnWidths[header] + cellPadding * 2,
          borderWidth: 1,
          borderColor: grayscale(0.5),
          color: backgrounds[j] ? hexToPdfRgb(backgrounds[j][header]) : rgb(1, 1, 1),
        });
        let value = data[j][header];
        if (header === 'basePrice') {
          value = numToCurrency.format(data[j][header]);
        }
        page.drawText(value ? value.toString() : '', {
          x: page.getX() + cellPadding,
          y: page.getY() + rowHeight - fontSize - cellPadding,
          size: fontSize,
          lineHeight: fontSize / 2,
        });
        page.moveRight(columnWidths[header] + cellPadding * 2);
      });
      page.moveLeft(tableWidth);
    }

    if (hotlistInfo) {
      page.drawText(time, { x: width - padding - timeWidth, y: height - padding - fontSize, size: fontSize });
      page.drawText(hotlistMessage, { x: padding, y: padding + fontSize * 2, size: fontSize, lineHeight: fontSize });
      page.drawText(registeredText, { x: (width - registeredWidth) / 2, y: padding, size: fontSize });
      if (hotlistInfo.realtor) {
        const long = Math.sqrt(Math.pow(realtorWidth, 2) / 2);
        const short = Math.sqrt(Math.pow(realtorFontSize, 2) / 2);
        const realtorSideLength = long + short;
        page.drawText(hotlistInfo.realtor, {
          x: (width - realtorSideLength) / 2,
          y: height - (height - realtorSideLength) / 2 - short,
          size: realtorFontSize,
          color: rgb(0.5, 0.5, 0.5),
          rotate: degrees(-45),
          opacity: 0.3,
        });
      }
    }
  }

  const pdfFile = await pdf.save();
  download(pdfFile, fileName, 'application/pdf');
};

export const getInnerText: (elem: React.ReactElement | string) => string = (elem: React.ReactElement | string) => {
  if (!elem) return '';
  if (typeof elem === 'string') return elem;
  const children = elem.props.children;
  if (children instanceof Array) return children.map(getInnerText).join('');
  return getInnerText(children);
};

export const getProjectDimensions = (id: string) => {
  if (id === '61535b00cb68b4620011d555') {
    return {
      pageSizeWidth: 850,
      pageSizeHeight: 752,
      jpgImageScale: 0.9,
      jpgImageWidth: 4,
      jpgImageHeight: 4,
    };
  } else if (id === '61d506dbaa581c623018e244') {
    return {
      pageSizeWidth: 825,
      pageSizeHeight: 760,
      jpgImageScale: 0.9,
      jpgImageWidth: 4,
      jpgImageHeight: 4,
    };
  } else
    return {
      pageSizeWidth: 850,
      pageSizeHeight: 752,
      jpgImageScale: 0.9,
      jpgImageWidth: 4,
      jpgImageHeight: 4,
    };
};

export const hyphenToTitle = (string: string) => {
  const result = capitalizeFirstLetterEachWord(string.replace(/-/g, ' '));
  return result;
};

export const handleHotlistHeaders = (projectId: string, headers: string[]) => {
  if (projectId === '63ed601560dfae53efa92b99') {
    const index = headers.findIndex((el) => el === 'Ceiling');
    headers[index] = 'Custom';
    return headers;
  } else return headers;
};

export const timeZoneDate = (date: Date) => {
  if (new Date(date).getTimezoneOffset() <= 0) {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60 + 5;
    return new Date(subHours(new Date(date), hours));
  } else {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60;
    if (hours <= 5) {
      return new Date(subHours(new Date(date), 5 - hours));
    } else {
      return new Date(addHours(new Date(date), hours - 5));
    }
  }
};

export const timeZoneHour = (date: Date) => {
  if (new Date(date).getTimezoneOffset() <= 0) {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60 + 5;
    return hours;
  } else {
    let hours = Math.abs(new Date(date).getTimezoneOffset()) / 60;
    return 5 - Math.abs(hours);
  }
};

export const customColumn = (projectId: string, headers: string[]) => {
  if (projectId === '63ed601560dfae53efa92b99') {
    const index = headers.findIndex((el) => el === 'Ceiling');
    headers[index] = 'Custom';
    return headers;
  } else return headers;
};

export const customHotlistData = (unit: IUnit, projectId: string) => {
  if (projectId === '646e965a0be1b1b0f611190d') {
    if (unit.exposure.includes('(C)')) {
      return 'Courtyard';
    } else if (unit.tier === 'Park') {
      return 'Golf';
    } else return 'City';
  } else return '';
};

export const sortSuites = (data: any, type: string) => {
  data.sort((a: any, b: any) => {
    //a and b are IUnits
    let keyA = a[type];
    let keyB = b[type];

    if (keyA === null && keyB === null) return 0;
    else if (keyA === null) return 1;
    else if (keyB === null) return -1;

    if (typeof keyA === 'number' && typeof keyB === 'number') return keyA - keyB;

    typeof keyA === 'string' ? keyA.toLowerCase() : (keyA = React.isValidElement(keyA) ? getInnerText(keyA) : keyA.toString());
    typeof keyB === 'string' ? keyB.toLowerCase() : (keyB = React.isValidElement(keyB) ? getInnerText(keyB) : keyB.toString());

    return keyA.localeCompare(keyB, 'en', { numeric: true });
  });

  return data;
};
